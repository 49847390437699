import React from "react";
import { Input, Button } from 'antd';
import { RegEmail, RegName, RegPassword, RegPhone } from "../../config/common/reg";
import { Cookie, getBrowserVersion, _handleTheMainSiteUrlOfBackendResponse, addAffiliateParamToUrl } from "../../actions/util";
import { fetchRequest, getPlatform } from "../../config/Fatch.config";
import { ApiPort } from "../../actions/API";
import Toast from '@/Toast';
class Register extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			EMAIL: '',
			PWD: '',
			USERNAME: '',
			PHONE: '',


			error_phone: false,
			error_name: false,
			error_pwd: false,
		}
		this.Register = this.Register.bind(this);
	}


	/**
	 * 注册
	 */

	RegInputNull() {
		let that = this;
		let { PHONE, PWD, USERNAME } = that.state;
		if(PHONE == ''){
			this.setState({
				error_phone: true,

			},()=>{
				document.getElementsByClassName('phone-hit-error')[0].innerHTML = 'กรุณาระบุเบอร์โทรศัพท์';
			})
		}
		if(PWD == ''){
			this.setState({
				error_pwd: true,
			},()=>{
				document.getElementsByClassName('pass-hit-error')[0].innerHTML = 'กรุณาระบุรหัสผ่าน';
			})
		}
		if(USERNAME == ''){
			this.setState({
				error_name: true,
			},()=>{
				document.getElementsByClassName('name-hit-error')[0].innerHTML = 'กรุณาระบุยูสเซอร์เนม';
			})
		}
	}

	Register() {
		let that = this;
		let { PHONE, PWD, USERNAME } = that.state;
		this.RegInputNull();
		if (RegName.test(USERNAME) && RegPhone.test(PHONE) && RegPassword.test(PWD)) {
			try {
				let params = {
					BlackBoxValue : (global.ioGetBlackbox && global.ioGetBlackbox().blackbox) || '' ,
					E2BlackBoxValue : (global.E2GetBlackbox && global.E2GetBlackbox().blackbox) || '' ,
					affiliateCode : Cookie.GetCookieKeyValue('CO_affiliate') || '' ,
					hidBlackbox : (global.ioGetBlackbox && global.ioGetBlackbox().blackbox) || '' ,
					hostName : document.domain,
					mediaCode : Cookie.GetCookieKeyValue('Media') || '' ,
					mobile : '66-' + PHONE,
					password : PWD,
					referer : Cookie.GetCookieKeyValue('Referer') || document.domain,
					username: USERNAME,
				}

				const platform = getPlatform();
				const Apiversion = `?api-version=1.0&brand=JBO&Platform=${platform}`;

				// piwik 追踪
				global.globalGtag('jbo_QRP', 'Registration form', 'Submit_registration', 'event');

				Toast.loading()
				fetchRequest(ApiPort.Register, 'POST', params, Apiversion).then(res => {
					if (res) {
						Toast.hide();
						if (res.isSuccess) {
							let updatedUrl = addAffiliateParamToUrl(res.url)
							_handleTheMainSiteUrlOfBackendResponse(`${updatedUrl}`)
						} else {
							this.props.openModal(res.message);
						}
					}
				}).catch(error => {
					console.log('服务器错误...');
				})
			} catch (error) {
				console.log('服务器错误...', error);
			}
		} else {
			return;
		}

	}

	/**
	 * 改变参数值
	 * @param { String } k 变量
	 * @param { String | Number | any } v 参数
	 */
	setStates(k, v) {
		this.setState({
			[k]: v
		});
	};

	/**
	 * onChange input
	 * @param {*} e
	 * @param {*} type
	 * @returns
	 */
	UserInput(e, type) {
		let val = e.target.value || '';
		this.setStates(type, val);
		this.regInput(e, type);
		return;
		// globalGtag(`Search_withdrawalrecord_Carddetail`);
	}

	/**
	 * input Blur
	 * @param {*} e
	 * @param {*} type
	 */
	inputOnBlur(e, type) {
		this.regInput(e, type);
	}


	/**
	 * 验证Input 方法
	 * @param {*} e
	 * @param {*} type
	 * @returns
	 */
	regInput(e, type) {
		let val = e.target.value || '';
		let that = this;
		let inputMap = {
			'USERNAME': (val) => {
				if (!RegName.test(val)) {
					that.setState({
						error_name: true
					}, () => {
						if (val === '') {
							document.getElementsByClassName('name-hit-error')[0].innerHTML = 'กรุณาระบุยูสเซอร์เนม';

						} else {
							document.getElementsByClassName('name-hit-error')[0].innerHTML = 'โปรดป้อน 6-14 ตัวอักษร (ตัวอักษรและตัวเลขเท่านั้น)';
						}

					});
				} else {
					that.setState({
						error_name: false
					}, () => {
						global.globalGtag && global.globalGtag('jbo_QRP' , 'Registration form' , 'Username_input' , 'input');
						document.getElementsByClassName('name-hit-error')[0].innerHTML = ''
					});
				}
				return;
			},
			'PWD': (val) => {
				if (!RegPassword.test(val)) {
					that.setState({
						error_pwd: true
					}, () => {
						if (val === '') {
							document.getElementsByClassName('pass-hit-error')[0].innerHTML = 'กรุณาระบุรหัสผ่าน';
						} else {
							document.getElementsByClassName('pass-hit-error')[0].innerHTML = 'รหัสผ่านควรประกอบด้วยตัวอักษรและตัวเลข 6-20 ตัว';
						}
					})

				} else {
					that.setState({
						error_pwd: false
					}, () => {
						global.globalGtag && global.globalGtag('jbo_QRP' , 'Registration form' , 'Password_input' , 'input');
						document.getElementsByClassName('pass-hit-error')[0].innerHTML = '';
					})
				}
				return;
			},

			'PHONE': (val) => {
				if (!RegPhone.test(val)) {
					that.setState({
						error_phone: true
					}, () => {
						if (val === '') {
							document.getElementsByClassName('phone-hit-error')[0].innerHTML = 'กรุณาระบุเบอร์โทรศัพท์';
						}
						// else {
						// 	document.getElementsByClassName('phone-hit-error')[0].innerHTML = 'หมายเลขโทรศัพท์ไม่ควรลงทะเบียนหรือเป็นของ บริษัท ใด ๆ';
						// }
					})
				} else {
					that.setState({
						error_phone: false
					}, () => {
						global.globalGtag && global.globalGtag('jbo_QRP' , 'Registration form' , 'phone_input' , 'input');
						document.getElementsByClassName('phone-hit-error')[0].innerHTML = '';
					})
				}
				return
			}
		}
		inputMap[type](val);
		return;
	}


	render() {
		let { USERNAME, PWD, EMAIL, PHONE } = this.state;
		return (
			<>
				<div className="register-box web-register" id="mobile-heading">
					<div className="box-head">
						<p>สมัครด่วน</p>
					</div>
					<div className="registration-content">
						<p className="tips">กรอกข้อมูลเพื่อเริ่มเกม สมาชิกใหม่ รับข้อเสนอพิเศษ</p>
						{/* 用户名 */}
						<div className="flexInput">
							<label>ยูสเซอร์เนม:</label>
							<Input value={USERNAME} onBlur={(e) => { this.inputOnBlur(e, 'USERNAME') }} type={'name'} onChange={(e) => { this.UserInput(e, 'USERNAME') }} maxLength={14} placeholder="กรุณากรอกยูสเซอร์เนม"></Input>
						</div>
						{/* 用户名错误显示 */}
						{<p className="name-hit-error"></p>}
						{/* 密码 */}
						<div className="flexInput">
							<label>รหัสผ่าน:</label>
							<Input type={'password'} onBlur={(e) => { this.inputOnBlur(e, 'PWD') }} onChange={(e) => { this.UserInput(e, 'PWD') }} maxLength={20} name="password" placeholder="กรุณากรอกรหัสผ่าน" value={PWD}></Input>
						</div>
						{ /* password error */}
						{<p className="pass-hit-error"></p>}
						{/* 邮箱 */}
						{/*<Input name="email" onBlur={(e)=>{this.inputOnBlur(e ,'EMAIL')}} maxLength={100} placeholder="邮箱" onChange={(e)=>{this.UserInput(e ,'EMAIL')}} value={EMAIL}></Input>*/}
						{ /* email error */}
						{/*<p className="email-hit-error"></p>*/}
						{/* 电话号码 */}
						<div className="phone-number flexInput">
							<label>เบอร์โทรศัพท์:</label>
							<Input className="phoneNumber" onBlur={(e) => { this.inputOnBlur(e, 'PHONE') }} maxLength={9} name="phoneNumber" id="inputPhone" placeholder="กรุณากรอกเบอร์โทรศัพท์" onChange={(e) => { this.UserInput(e, 'PHONE') }} value={PHONE}></Input>
						</div>
						{ /* email iphone Number */}
						{<p className="phone-hit-error"></p>}

						<p className="tips">คลิกปุ่ม [สมัคร] เพื่อระบุว่าคุณมีอายุมากกว่า 18 ปีและ ยอมรับนโยบายทั้งหมดที่ระบุไว้ในกฎและเงื่อนไข</p>
						<Button className="register-submit btn-download-pc" onClick={this.Register}>สมัคร</Button>
						<p className="tips">JBO เก็บรักษาข้อมูลคุณที่ให้ไว้เป็นความลับอย่างเคร่งครัดและข้อมูลทั้งหมดที่คุณให้สามารถรับประกันได้ว่าเป็นความลับ</p>

					</div>


				</div>



				{/* Mobile Ipad */}

			</>

		)
	}
}


export default Register;
